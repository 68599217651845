/**
 * @description 时间格式化
 * @param {number} time
 * @returns {string}
 */
export function formatTime(time) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()
  const diff = (now - d) / 1000
  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  return (
    d.getMonth() + 1 +'月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  )
}

/**
 * @description 防抖
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result
  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp
    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) { context = args = null }
      }
    }
  }
  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) { timeout = setTimeout(later, wait) }
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }
    return result
  }
}

/**
 * @description 深拷贝
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @description 金额分段，000,111,11.00
 * @param {number} money
 * @return {string}
 */
export function moneySplit(money) {
  return Number(money).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
}

/**
 * @description 字符串插入字符
 * @param {string} str 字符串本身
 * @param {string} flg 要插入的字符串
 * @param {Number} sn 要插入的位置
 * @return {string}
 */
export function insertString(str, flg, sn) {
  let newstr = ''
  for (let i = 0; i < str.length; i += sn) {
    const tmp = str.substring(i, i + sn)
    newstr += tmp + flg
  }
  return newstr
}

/**
 * @description 获取时间戳
 * @return {string}
 */
export function getTimeStamp() {
  return (new Date()).valueOf()
}