import "core-js/modules/es.array.push.js";
import { reactive, toRefs, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
// import { navList } from '@/util/data'
import { deepClone } from "@/util/funtion";
import request from "../util/request";
export default {
  name: "footer-custom",
  setup() {
    const router = useRouter();
    const language = ref(sessionStorage.getItem("language"));
    const footerLogo = ref(require("../assets/mobile/footer-logo.webp"));
    const footerLogoEn = ref(require("../assets/mobile/footer-logoEn.webp"));
    const data = reactive({
      navList: [],
      newNavList1: [],
      newNavList: [],
      navlistMb: []
    });
    const ispc = ref(true);
    const activeName = ref(null);
    const toEmail = () => {
      // Foxmail邮箱地址
      var foxmailEmail = "mailto:liuz@jodell.cn";
      // 打开一个新窗口或者新标签，导航到Foxmail地址
      window.open(foxmailEmail, "_blank");
    };
    onMounted(() => {
      getNavList();
      if (document.documentElement.clientWidth > 1270) {
        ispc.value = true;
      } else {
        ispc.value = false;
      }
      window.addEventListener("resize", () => {
        if (document.documentElement.clientWidth > 1270) {
          ispc.value = true;
        } else {
          ispc.value = false;
        }
      });
    });
    const getNavList = async () => {
      const res = await request({
        url: "/jodell/front/getMenus",
        method: "get",
        params: {}
      });
      const listMb = deepClone(res);
      data.navlistMb = listMb;
      const list = deepClone(res);
      data.navList = list;
      data.navList.shift();
      data.navList.pop();
      // console.log(data.navList);
      data.newNavList1 = data.navList.slice(0, 3);
      data.newNavList = data.navList.slice(3, data.navList.length);
    };
    const chooseNav = path => {
      router.push({
        name: path
      });
    };
    const chooseNavMb = (e, path) => {
      e.stopPropagation();
      router.push({
        name: path
      });
    };
    const handleChange = name => {
      activeName.value = name;
    };
    const chooseChildNav = (path, childPath, id) => {
      if (path === "product-index" || path === "contact-index") {
        router.push({
          name: childPath,
          query: {
            id: id
          }
        });
      }
      if (path === "resolve-index") {
        router.push({
          name: childPath,
          query: {
            id: id,
            name: "resolve"
          }
        });
      }
      if (path === "company-introduce") {
        if (id) {
          router.push("/company-introduce#" + id);
        } else {
          router.push("/company-introduce");
        }
      }
      if (path === "new-center") {
        router.push({
          name: path,
          query: {
            id: id
          }
        });
      }
      if (path === "dowmload-center") {
        router.push({
          name: path,
          query: {
            id: id
          }
        });
      }
      if (path === "jodell-ecology") {
        if (id == 2 || id == 3 || id == 11 || id == 9) {
          router.push({
            name: childPath,
            query: {
              id: id
            }
          });
        }
      }
    };
    return {
      ...toRefs(data),
      chooseNav,
      chooseChildNav,
      ispc,
      activeName,
      handleChange,
      chooseNavMb,
      language,
      toEmail,
      footerLogo,
      footerLogoEn
    };
  }
};