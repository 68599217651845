import HeaderCustom from "@/components/HeaderCustom";
import FooterCustom from "@/components/FooterCustom";
import { store } from "@/store/store";
import { ref, reactive, toRefs } from "vue";
import { ElMessage } from "element-plus";
import request from "@/util/request";
export default {
  name: "home-page",
  components: {
    HeaderCustom,
    FooterCustom
  },
  setup() {
    const isPhoneLogin = ref(false);
    const isSetPassword = ref(false);
    const timeOut = ref(60);
    const data = reactive({
      userId: null,
      userCode: "",
      login: {
        userCode: "",
        userPassword: ""
      },
      register: {
        userCode: "",
        code: ""
      },
      newPassword: "",
      userName: "",
      userEmail: "",
      industry: "",
      companyName: ""
    });
    const getCode = async () => {
      if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(data.register.userCode)) {
        ElMessage.warning("请输入正确的手机号码");
        return false;
      }
      await request({
        url: "/jodell/sendPhoneMessage/getPhoneMessage",
        method: "get",
        params: {
          phone: data.register.userCode
        }
      });
      ElMessage.success("验证码发送成功");
      const timer = setInterval(() => {
        if (timeOut.value < 1) {
          timeOut.value = 60;
          clearInterval(timer);
        } else {
          timeOut.value--;
        }
      }, 1000);
    };
    const registerHandle = async () => {
      if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(data.register.userCode)) {
        ElMessage.warning("请输入正确的手机号码");
        return false;
      }
      if (!data.register.code) {
        ElMessage.warning("请输入验证码");
        return false;
      }
      const res = await request({
        url: "/jodell/login/checkPhoneCode",
        method: "post",
        data: data.register
      });
      data.userId = res.userId;
      sessionStorage.setItem("JR-userId", res.userId);
      store.isLogin = true;
      store.userName = res.userName;
      if (res.hasPassword === 1) {
        ElMessage.success("登录成功");
        isPhoneLogin.value = false;
        isSetPassword.value = false;
        store.isOpenLoginModal = false;
      } else {
        ElMessage.success("登录成功，请设置用户信息");
        isSetPassword.value = true;
      }
    };
    const setPassword = async () => {
      if (!/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,20}$/.test(data.newPassword)) {
        ElMessage.warning("请输入6-20位字母、数字、特殊字符组合密码");
        return false;
      }
      if (!data.userName) {
        ElMessage.warning("请输入用户名");
        return false;
      }
      if (!data.userEmail) {
        ElMessage.warning("请输入电子邮箱");
        return false;
      }
      if (!data.industry) {
        ElMessage.warning("请输入行业名称");
        return false;
      }
      if (!data.companyName) {
        ElMessage.warning("请输入公司名称");
        return false;
      }
      await request({
        url: "/jodell/login/saveExtend",
        method: "post",
        data: {
          userId: data.userId,
          newPassword: data.newPassword,
          userName: data.userName,
          userEmail: data.userEmail,
          industry: data.industry,
          companyName: data.companyName
        }
      });
      ElMessage.success("密码保存成功");
      isPhoneLogin.value = false;
      isSetPassword.value = false;
      store.isOpenLoginModal = false;
      store.userName = data.userName;
    };
    const loginHandle = async () => {
      if (!data.login.userCode) {
        ElMessage.warning("请输入手机号");
        return false;
      }
      if (!data.login.userPassword) {
        ElMessage.warning("请输入密码");
        return false;
      }
      const res = await request({
        url: "/jodell/login/userLoginFront",
        method: "post",
        data: data.login
      });
      ElMessage.success("登录成功");
      store.isOpenLoginModal = false;
      data.userId = res.userId;
      data.userCode = res.userCode;
      sessionStorage.setItem("JR-userId", res.userId);
      store.isLogin = true;
      store.userName = res.userCode;
    };
    return {
      store,
      isPhoneLogin,
      isSetPassword,
      timeOut,
      ...toRefs(data),
      getCode,
      registerHandle,
      setPassword,
      loginHandle
    };
  }
};