import "core-js/modules/es.array.push.js";
import { useRouter, useRoute } from "vue-router";
import { reactive, ref, toRefs, onMounted } from "vue";
import { deepClone } from "@/util/funtion";
import request from "../util/request";
import { store } from "@/store/store";
export default {
  name: "header-custom",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const logoImg = ref(require("../assets/mobile/logo.webp"));
    const logoImgEn = ref(require("../assets/logoEn.webp"));
    const menuShow = ref(false);
    const ispc = ref(true);
    const language = ref(sessionStorage.getItem("language"));
    const navChoose = ref("index");
    const data = reactive({
      navlistPc: [],
      navlistMb: [],
      options: [{
        name: "中文",
        value: "cn"
      }, {
        name: "English",
        value: "en"
      }, {
        name: "Japanese",
        value: "jp"
      }, {
        name: "Korean",
        value: "kr"
      }]
    });
    onMounted(() => {
      if (document.documentElement.clientWidth > 1270) {
        ispc.value = true;
      } else {
        ispc.value = false;
      }
      window.addEventListener("resize", () => {
        if (document.documentElement.clientWidth > 1270) {
          ispc.value = true;
        } else {
          ispc.value = false;
        }
      });
      const userId = sessionStorage.getItem("JR-userId");
      if (userId) {
        store.isLogin = true;
      } else {
        store.isLogin = false;
      }
      getNavList();
    });
    const getNavList = async () => {
      const res = await request({
        url: "/jodell/front/getMenus",
        method: "get",
        params: {}
      });
      data.navlistPc = deepClone(res);
      data.navlistPc.pop();
      data.navlistMb = deepClone(res);
    };
    const chooseNav = path => {
      if (route.name == path) {
        return router.go(0);
      } else {
        navChoose.value = path;
        router.push({
          name: path
        });
        menuShow.value = false;
      }
    };
    const changeLanguage = value => {
      sessionStorage.setItem("language", value);
      if (value == "en") {
        document.title = "Electric gripper - JODELL Robotics provides the most competitive executive components for industry automation";
      } else {
        document.title = "钧舵机器人Jodell Robotics";
      }
      router.go(0);
    };
    /* const chooseChildNav = (path, childPath, id) => {
      navChoose.value = path;
      if (
        path === "product-index" ||
        path === "resolve-index" ||
        path === "contact-index"
      ) {
        router.push({ name: childPath, query: { id: id } });
      }
      if (path === "company-introduce") {
        router.push("/company-introduce#" + id);
      }
      if (path === "dowmload-center" || path === "new-center") {
        router.push({ name: path, query: { id: id } });
      }
      if (path === "JodellEcology") {
        router.push({ name: childPath, query: { id: id } });
      }
      menuShow.value = false;
    }; */

    return {
      ...toRefs(data),
      navChoose,
      menuShow,
      chooseNav,
      /* chooseChildNav, */
      store,
      ispc,
      changeLanguage,
      language,
      logoImg,
      logoImgEn
    };
  }
};