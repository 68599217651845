import {
  createApp
} from "vue";
import App from "./App.vue";
import router from "./router";

import "./style/base.css";
import "./style/gloab.css";
import "animate.css";
/* import ElementPlus from "element-plus";
import "element-plus/dist/index.css"; */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./style/font.css";
import toEmail from "@/components/emailDiv.vue"
import getQuote from "@/components/getQuote.vue"
import toAmap from "@/components/toAmap.vue"

// 引入懒加载指令插件并注册
import {
  lazyPlugin
} from "@/util/lazyLoad";

router.afterEach(() => {
  let bodySrcollTop = document.body.scrollTop;
  if (bodySrcollTop !== 0) {
    document.body.scrollTop = 0;
    return;
  }
  let docSrcollTop = document.documentElement.scrollTop;
  if (docSrcollTop !== 0) {
    document.documentElement.scrollTop = 0;
  }
});

const app = createApp(App);
app.component("toEmail", toEmail);
app.component("getQuote", getQuote);
app.component("toAmap", toAmap);
app.use(router).use(lazyPlugin).mount("#app");