import Cookies from 'js-cookie'

const TokenKey = 'admin-token'

export function setToken(tokenValue) {
  return Cookies.set(TokenKey, tokenValue)
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}