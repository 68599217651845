import "core-js/modules/es.array.push.js";
import { reactive, toRefs, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "back-home-page",
  setup() {
    const router = useRouter();
    const language = ref(sessionStorage.getItem("language"));
    const data = reactive({
      navList: [{
        id: 1,
        name: "首页",
        path: "back-index"
      }, {
        id: 4,
        name: "产品管理",
        path: "product-manage"
      }, {
        id: 2,
        name: "新闻中心",
        path: "back-new-list"
      }, {
        id: 5,
        name: "资源管理",
        path: "resource-manage"
      }, {
        id: 3,
        name: "报价咨询",
        path: "prize-consult"
      }, {
        id: 3,
        name: "会员管理",
        path: "vip-manage"
      }],
      navChoose: "back-index",
      options: [{
        name: "中文",
        value: "cn"
      }, {
        name: "英文",
        value: "en"
      }, {
        name: "日文",
        value: "jp"
      }, {
        name: "韩文",
        value: "kr"
      }]
    });
    const chooseNav = path => {
      data.navChoose = path;
      router.push({
        name: path
      });
    };
    const changeLanguage = value => {
      sessionStorage.setItem("language", value);
      router.go(0);
    };
    return {
      ...toRefs(data),
      chooseNav,
      language,
      changeLanguage
    };
  }
};