import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../assets/contactUs/amap.png';
import _imports_1 from '../assets/contactUs/amapMb.png';
const _withScopeId = n => (_pushScopeId("data-v-1e028d16"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "amap-box"
};
const _hoisted_2 = {
  key: 0,
  style: {
    "max-width": "1920px"
  },
  src: _imports_0
};
const _hoisted_3 = {
  key: 1,
  style: {
    "width": "100%",
    "min-width": "375px"
  },
  src: _imports_1
};
const _hoisted_4 = {
  class: "amap-name"
};
const _hoisted_5 = {
  class: "amap-address"
};
const _hoisted_6 = {
  class: "amap-name"
};
const _hoisted_7 = {
  class: "amap-address"
};
const _hoisted_8 = {
  class: "amap-phonebox"
};
const _hoisted_9 = {
  class: "amap-phone"
};
const _hoisted_10 = {
  class: "amap-phonenumber"
};
const _hoisted_11 = {
  class: "amap-name"
};
const _hoisted_12 = {
  class: "amap-address"
};
import { ref, onMounted } from "vue";
export default {
  __name: 'toAmap',
  setup(__props) {
    const language = ref(sessionStorage.getItem("language"));
    const toAmap = type => {
      console.log(type);
      let name = "";
      let lat = 0;
      let lng = 0;
      if (type == "sh") {
        name = "上海市浦东新区秀浦路2388号康桥先进制造技术创业园2号楼1208";
        lat = 31.12826588323738;
        lng = 121.6052855551243;
      } else if (type == "js") {
        name = "江苏省苏州市吴江汾湖高新技术开发区临沪大道1508号腾飞工业坊2期2幢3楼钧舵机器人";
        lat = 31.025670988674978;
        lng = 120.81218451261518;
      } else if (type == "gd") {
        name = "广东省东莞市南城·联科国际信息产业园1栋502室";
        lat = 22.95512950829761;
        lng = 113.71866703033446;
      }
      let url = " https://uri.amap.com/marker?position=" + lng + "," + lat + "&name=" + name;
      window.open(url, "_blank");
    };
    const ispc = ref(true);
    onMounted(() => {
      if (document.documentElement.clientWidth > 1270) {
        ispc.value = true;
      } else {
        ispc.value = false;
      }
      window.addEventListener("resize", () => {
        if (document.documentElement.clientWidth > 1270) {
          ispc.value = true;
        } else {
          ispc.value = false;
        }
      });
    });
    const getInfoSh = () => {
      let list = {
        title: "上海办事处",
        address: "上海市浦东新区秀浦路2388号康桥先进制造技术创业园2号楼1208"
      };
      switch (language.value) {
        case "cn":
          list = {
            title: "上海办事处",
            address: "上海市浦东新区秀浦路2388号康桥先进制造技术创业园2号楼1208"
          };
          break;
        case "en":
          list = {
            title: "Shanghai Office",
            address: "Room 1208, Building 2, Kangqiao Advanced Manufact uring Technology 2388 Xiupu Road,Innovation Park Pudong New District, Shanghai"
          };
          break;
        case "jp":
          list = {
            title: "上海オフィス",
            address: "上海市浦東新区秀浦路2388号康橋先進製造技術創業園2号棟1208室"
          };
          break;
        case "kr":
          list = {
            title: "상해 사무소",
            address: "상해시 포동신구 수포로 2388호 강교선진제조기술창업원 2호동 1208"
          };
          break;
        default:
          break;
      }
      return list;
    };
    const getInfoJs = () => {
      let list = {
        title: "苏州总部",
        address: "江苏省苏州市吴江汾湖高新技术开发区临沪大道1508号腾飞工业坊2期2幢3楼钧舵机器人",
        phone: "电话：",
        phoneNumber: "0512-63025073"
      };
      switch (language.value) {
        case "cn":
          list = {
            title: "苏州总部",
            address: "江苏省苏州市吴江汾湖高新技术开发区临沪大道1508号腾飞工业坊2期2幢3楼钧舵机器人",
            phone: "电话：",
            phoneNumber: "0512-63025073"
          };
          break;
        case "en":
          list = {
            title: "Suzhou Headquarters",
            address: "Floor 3, Building 2, Phase 2, Tengfei Industrial Park,1508 Linhu Avenue, Fen Lake High-Tech DevelopmentZone,Wujiang District, Suzhou City, Jiangsu Province",
            phone: "Phone:",
            phoneNumber: "0512-63025073"
          };
          break;
        case "jp":
          list = {
            title: "蘇州本社",
            address: "江蘇省蘇州市呉江区汾湖ハイテク開発区臨沪大道1508号騰飛工業坊2期2幢3階Jodell Robotics",
            phone: "TEL:",
            phoneNumber: "0512-63025073"
          };
          break;
        case "kr":
          list = {
            title: "소주 본부",
            address: "강소성 소주시 오강분호고신기술개발구 임호대로1508호 등비공업방 2기 2동 3층 조델로보틱스",
            phone: "전화:",
            phoneNumber: "0512-63025073"
          };
          break;
        default:
          break;
      }
      return list;
    };
    const getInfoGd = () => {
      let list = {
        title: "华南办事处",
        address: "广东省东莞市南城·联科国际信息产业园1栋502室"
      };
      switch (language.value) {
        case "cn":
          list = {
            title: "华南办事处",
            address: "广东省东莞市南城·联科国际信息产业园1栋502室"
          };
          break;
        case "en":
          list = {
            title: "South China Office",
            address: "Room 502, Building 1, Lianke International Information Industry Park, Nancheng, Dongguan City, Guangdong Province"
          };
          break;
        case "jp":
          list = {
            title: "華南エリアオフィス",
            address: "広東省東莞市南城区聯科国際情報産業園1号棟502室"
          };
          break;
        case "kr":
          list = {
            title: "화남 사무소",
            address: "광동성 동관시 남성 · 연과국제신식생업원 1동 502호"
          };
          break;
        default:
          break;
      }
      return list;
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [ispc.value ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : _createCommentVNode("", true), !ispc.value ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : _createCommentVNode("", true), _createElementVNode("div", {
        class: "title-box",
        onClick: _cache[0] || (_cache[0] = $event => toAmap('sh'))
      }, [_createElementVNode("div", _hoisted_4, _toDisplayString(getInfoSh().title), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(getInfoSh().address), 1)]), _createElementVNode("div", {
        class: "title-box2",
        onClick: _cache[1] || (_cache[1] = $event => toAmap('js'))
      }, [_createElementVNode("div", _hoisted_6, _toDisplayString(getInfoJs().title), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(getInfoJs().address), 1), _createElementVNode("div", _hoisted_8, [_createElementVNode("span", _hoisted_9, _toDisplayString(getInfoJs().phone), 1), _createElementVNode("span", _hoisted_10, _toDisplayString(getInfoJs().phoneNumber), 1)])]), _createElementVNode("div", {
        class: "title-box3",
        onClick: _cache[2] || (_cache[2] = $event => toAmap('gd'))
      }, [_createElementVNode("div", _hoisted_11, _toDisplayString(getInfoGd().title), 1), _createElementVNode("div", _hoisted_12, _toDisplayString(getInfoGd().address), 1)])]);
    };
  }
};