export const detectZoom = () => {
  let ratio = 0,
    screen = window.screen,
    ua = navigator.userAgent.toLowerCase();
  //判断浏览器类型
  if (window.devicePixelRatio !== undefined) {
    ratio = window.devicePixelRatio;
  } else if (~ua.indexOf("msie")) {
    //是否为IE浏览器
    if (screen.deviceXDPI && screen.logicalXDPI) {
      ratio = screen.deviceXDPI / screen.logicalXDPI;
    }
  } else if (
    window.outerWidth !== undefined &&
    window.innerWidth !== undefined
  ) {
    ratio = window.outerWidth / window.innerWidth; //outerWidth为用户屏幕分辨率 innerWidth为浏览器窗口的宽度
  }
  if (ratio) {
    ratio = Math.round(ratio * 100);
  }
  return ratio;
};
